// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-success {
    --bs-table-color: #fff !important;
    --bs-table-bg: #4F6F52 !important;
    --bs-table-border-color: #bcd0c7;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
}


/* .border{
    width: 450px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    
}
.next{
    
   margin-left: 40%;
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,iCAAiC;IACjC,gCAAgC;IAChC,8BAA8B;IAC9B,8BAA8B;IAC9B,6BAA6B;IAC7B,6BAA6B;IAC7B,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,0CAA0C;AAC9C;;;AAGA;;;;;;;;;GASG","sourcesContent":[".table-success {\n    --bs-table-color: #fff !important;\n    --bs-table-bg: #4F6F52 !important;\n    --bs-table-border-color: #bcd0c7;\n    --bs-table-striped-bg: #c7dbd2;\n    --bs-table-striped-color: #fff;\n    --bs-table-active-bg: #bcd0c7;\n    --bs-table-active-color: #000;\n    --bs-table-hover-bg: #c1d6cc;\n    --bs-table-hover-color: #000;\n    color: var(--bs-table-color);\n    border-color: var(--bs-table-border-color);\n}\n\n\n/* .border{\n    width: 450px;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n    text-align: center;\n    \n}\n.next{\n    \n   margin-left: 40%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
